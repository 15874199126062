<template>
  <c-flex flex-dir="column">
    <Header logo="hide" :back="headerBack" :back-title="headerTitle" />

    <c-box
      :max-w="['inherit', '1200px']"
      w="100%"
      mx="auto"
      :mt="['24px', '60px']"
    >
      <c-breadcrumb separator="›" :d="['none', 'block']">
        <c-breadcrumb-item>
          <c-breadcrumb-link as="router-link" to="/">
            Beranda
          </c-breadcrumb-link>
        </c-breadcrumb-item>
        <c-breadcrumb-item>
          <c-breadcrumb-link as="router-link" to="/content-challenge">
            Diet and Coaching Challenge
          </c-breadcrumb-link>
        </c-breadcrumb-item>
      </c-breadcrumb>

      <c-flex
        justify-content="center"
        max-w="1200px"
        :border-top="[null, '1px solid #F2F2F2']"
        :mt="[null, '30px']"
      >
        <Sidemenu v-if="isClient" />
        <c-flex
          :mt="[null, '30px']"
          :ml="!isNutri ? [null, '52px'] : ''"
          :w="!isNutri ? ['100%', '850px'] : ''"
          :flexGrow="isNutri ? 1 : ''"
        >
          <router-view />
        </c-flex>
      </c-flex>
    </c-box>

    <NavBottom v-if="isNutri" />
    <Footer />
  </c-flex>
</template>

<script>
import { mapState } from "vuex";
import Header from "@/components/header/index.vue";
import NavBottom from "@/components/nav-bottom.vue";
import Footer from "@/components/Footer.vue";
import Sidemenu from "./sidemenu.vue";
export default {
  name: "ContentChallengeLayout",
  components: {
    Header,
    NavBottom,
    Footer,
    Sidemenu,
  },
  computed: {
    ...mapState({
      user: (s) => s.auth.user,
    }),
    isNutri() {
      if (this.user && this.user.role == "nutritionist") return true;
      return false;
    },
    isClient() {
      if (this.user && this.user.role == "client") return true;
      return false;
    },
    headerBack() {
      if (this.isClient) {
        return "client.index";
      }
      return "";
    },
    headerTitle() {
      if (this.isClient) {
        return "Diet Coaching & Challenge";
      }
      return "Coaching Course";
    },
  },
};
</script>

<style scoped>
body {
  overflow: inherit;
}
[data-chakra-component="CBreadcrumb"] {
  color: var(--colors-brand-900);
}

[data-chakra-component="CBreadcrumb"]
  /deep/
  [data-chakra-component="CBreadcrumbItem"] {
  color: var(--colors-brand-900);
}
[data-chakra-component="CBreadcrumb"]
  /deep/
  [data-chakra-component="CBreadcrumbItem"]:last-child {
  color: #333;
}
</style>