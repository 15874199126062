var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('c-flex',{attrs:{"as":"nav","id":"profileNavigation","position":['fixed', 'static'],"bottom":[0],"h":['60px', 'auto'],"w":['100vw', 'auto'],"background":"white","align-items":"center","justify-content":"space-around","border-right":"1px solid #f2f2f2","box-shadow":['4px -4px 50px 5px rgba(0, 0, 0, 0.15)', 'none'],"d":['flex', 'block'],"z-index":[999, 0],"mt":[null, '30px'],"pr":"30px"}},_vm._l((_vm.menus),function(menu){return _c('c-link',{key:menu.name,staticClass:"menu-item",class:{ active: menu.isActive },attrs:{"as":"router-link","to":menu.route,"exact-active-class":"active"}},[_c('c-flex',{directives:[{name:"chakra",rawName:"v-chakra",value:({
        '.active &': {
          bg: [null, '#f5f5f5'],
        },
      }),expression:"{\n        '.active &': {\n          bg: [null, '#f5f5f5'],\n        },\n      }"}],attrs:{"flex-direction":['column', 'row'],"justify-content":['center', 'flex-start'],"align-items":"center","h":[null, '66px'],"w":[null, '327px'],"border-radius":[null, '12px']}},[_c('c-box',{directives:[{name:"chakra",rawName:"v-chakra",value:({
          '.active &': {
            bg: [null, 'brand.900'],
          },
        }),expression:"{\n          '.active &': {\n            bg: [null, 'brand.900'],\n          },\n        }"}],attrs:{"w":"7px","h":"100%","border-top-left-radius":"15px","border-bottom-left-radius":"15px"}}),_c('c-image',{staticClass:"icon",attrs:{"alt":menu.name,"src":menu.icon,"w":"24px","h":"24px","ml":[null, '25px']}}),_c('c-text',{attrs:{"font-size":['10px', '16px'],"font-weight":['500'],"ml":[null, '20px'],"color":menu.isActive ? 'brand.900' : '#555555'}},[_vm._v(" "+_vm._s(menu.name)+" ")])],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }