<template>
  <c-flex
    as="nav"
    id="profileNavigation"
    :position="['fixed', 'static']"
    :bottom="[0]"
    :h="['60px', 'auto']"
    :w="['100vw', 'auto']"
    background="white"
    align-items="center"
    justify-content="space-around"
    border-right="1px solid #f2f2f2"
    :box-shadow="['4px -4px 50px 5px rgba(0, 0, 0, 0.15)', 'none']"
    :d="['flex', 'block']"
    :z-index="[999, 0]"
    :mt="[null, '30px']"
    pr="30px"
  >
    <c-link
      as="router-link"
      v-for="menu in menus"
      :key="menu.name"
      :to="menu.route"
      class="menu-item"
      :class="{ active: menu.isActive }"
      exact-active-class="active"
    >
      <c-flex
        :flex-direction="['column', 'row']"
        :justify-content="['center', 'flex-start']"
        align-items="center"
        :h="[null, '66px']"
        :w="[null, '327px']"
        :border-radius="[null, '12px']"
        v-chakra="{
          '.active &': {
            bg: [null, '#f5f5f5'],
          },
        }"
      >
        <c-box
          w="7px"
          h="100%"
          border-top-left-radius="15px"
          border-bottom-left-radius="15px"
          v-chakra="{
            '.active &': {
              bg: [null, 'brand.900'],
            },
          }"
        />
        <c-image
          class="icon"
          :alt="menu.name"
          :src="menu.icon"
          w="24px"
          h="24px"
          :ml="[null, '25px']"
        />
        <c-text
          :font-size="['10px', '16px']"
          :font-weight="['500']"
          :ml="[null, '20px']"
          :color="menu.isActive ? 'brand.900' : '#555555'"
        >
          {{ menu.name }}
        </c-text>
      </c-flex>
    </c-link>
  </c-flex>
</template>

<script>
export default {
  name: "nav-bottom",
  computed: {
    menus() {
      const self = this;
      return [
        {
          name: "Daily Coaching",
          route: { name: "content-challenge.daily-coaching" },
          get icon() {
            if (this.isActive) {
              return require("@/assets/icon-calendar-event-active.svg");
            } else {
              return require("@/assets/icon-calendar-event.svg");
            }
          },
          get isActive() {
            return self.$route.name === "content-challenge.daily-coaching";
          },
        },
        {
          name: "Coaching Course",
          route: { name: "content-challenge.coaching-course" },
          get icon() {
            if (this.isActive) {
              return require("@/assets/icon-target-active.svg");
            }
            return require("@/assets/icon-target.svg");
          },
          get isActive() {
            return self.$route.name === "content-challenge.coaching-course";
          },
        },
        {
          name: "Diet Challenge",
          route: { name: "content-challenge.diet-challenge" },
          get icon() {
            if (this.isActive) return require("@/assets/icon-flag-active.svg");
            return require("@/assets/icon-flag.svg");
          },
          get isActive() {
            return self.$route.name === "content-challenge.diet-challenge";
          },
        },
      ];
    },
  },
};
</script>

<style>
body {
  padding-bottom: 75px;
}
</style>
